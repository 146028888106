@font-face {
  font-family: 'Space Mono';
  src: url('../assets/fonts/space-mono/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@mixin space-mono {
  font-family: 'Space Mono', arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #333;

  @include space-mono;
}

.webgl {
  background-color: #d99058;
  background-image: linear-gradient(315deg, #d99058 0%, #f8de7e 74%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

h1.title {
  font-size: 2.5em;
  margin-bottom: 0;
}

h3.subtitle {
  margin-top: 0;
  font-size: 1.29em;
}

ul.announchment {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 3em;
  margin-top: 20vh;

  a {
    color: #333;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  h1.title {
    font-size: 2em;
  }

  h3.subtitle {
    font-size: 1.03em;
  }

  ul.announchment {
    font-size: 2em;
    margin-top: 30vh;
  }
}
